import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import {
  Section,
  SectionTitle,
  BreadCrumb,
  MarkdownContent,
} from "../components/Section"
import CustomBuildingQuote from "../components/CustomBuildingQuote"
import BreakpointUp from "../components/Media/BreakpointUp"

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }
`

const SectionWhite = styled.div`
  background-color: #fff;
  position: relative;
  max-width: 1120px;
  margin: 0 auto;
  width: 100%;
  top: -20px;
  padding: 30px 10px;
  ${BreakpointUp.md`  
      top:-30px;
      padding:30px;
    `}
  ${BreakpointUp.lg`  
      top:-60px;
      padding:60px 85px;
    `}
`

const TopHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 0 auto;
  max-width: 1120px;
  width: 100%;
`
const TopHeaderFigure = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  figure {
    position: relative;
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }
`
const TopHeaderTitle = styled.h1`
  display: block;
  margin: 0 0 15px;
  text-align: center;
`

const InfographicDetail = ({ data, location }) => {
  const pageData = data.contentfulInfographic
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <Link to="/infographics">Infographics / </Link>
          <span>{pageData.title}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="150px"
        xpt="120px"
        xpb="90px"
        bgColor="#FAFAFA"
        bdrBottom="#707070"
      >
        <BackgroundImage>
          <GatsbyImage
            image={pageData.image.gatsbyImageData}
            alt={pageData.image.title}
          />
        </BackgroundImage>
        <div className="container">
          <TopHeader>
            <TopHeaderTitle>{pageData.title}</TopHeaderTitle>
            <TopHeaderFigure>
              <figure>
                <GatsbyImage
                  image={pageData.image.gatsbyImageData}
                  alt={pageData.image.title}
                />
              </figure>
            </TopHeaderFigure>
          </TopHeader>
        </div>
      </Section>
      <Section pt="0" pb="0" xpt="0" xpb="0" bgColor="#fff">
        <div className="container">
          <SectionWhite>
            <MarkdownContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.content.childMarkdownRemark.html,
                }}
              />
            </MarkdownContent>
          </SectionWhite>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle maxWidth="700px" mb="60px">
            Get Your Custom Building Quote in Just a few Clicks
          </SectionTitle>
          <CustomBuildingQuote states={data.allContentfulState.edges} />
        </div>
      </Section>
    </Layout>
  )
}

export default InfographicDetail

export const pageQuery = graphql`
  query InfographicDetailQuery($id: String!) {
    contentfulInfographic(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      image {
        title
        gatsbyImageData
      }
      createdAt(formatString: "DD MMM")
      description {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
